<template>
  <section
    v-if="!userStore.$state.cookieAgrement"
    class="cookie animate__animated animate__backInUp"
    :class="{ animate__backOutDown: !showCookieAgrement }"
  >
    <div class="cookie__section">
      <img class="cookie__section-icon" src="~/assets/icons/cookies.svg" alt="img" />
      <article class="cookie__section-art">
        Наш сайт использует файлы cookie.
        <a
          :href="`${currenDomen}/docs/docs.html#politika`"
          target="_blank"
          rel="noopener noreferrer"
          >Подробнее</a
        >
      </article>
      <button v-ripple.early:white @click="acceptCookie" class="cookie__section-butt">Ok</button>
    </div>
  </section>
</template>

<script setup lang="ts">
import { getLocalStorage, writePersistentLocalStorage } from '~/services/LocalStorage/localStorage'
import { useFormStore } from '~/store/user'
import { setCookieExpires } from '~/helpers/Cookie/setCookie'

const userStore = useFormStore()

const currenDomen = ref(window.location.origin)
const showCookieAgrement = ref(true)
const acceptCookie = () => {
  const currentDate = new Date()
  writePersistentLocalStorage('USER_COOKIE_AGREMENT', currentDate)
  setCookieExpires()
  setTimeout(() => {
    showCookieAgrement.value = false
  }, 500)
  setTimeout(() => {
    showCookieAgrement.value = false
    userStore.$state.cookieAgrement = true
  }, 2000)
}
onBeforeMount(() => {
  const isAgreed = getLocalStorage('USER_COOKIE_AGREMENT')
  showCookieAgrement.value = !isAgreed
  userStore.$state.cookieAgrement = !showCookieAgrement.value
})
</script>

<style scoped lang="scss">
.cookie {
  position: fixed;
  bottom: 16px;
  width: calc(100vw - 65px);
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 999999;
  &__section {
    padding: 8px 8px 8px 16px;
    align-items: center;
    width: fit-content;
    height: fit-content;
    border-radius: 12px;
    background: var(--white, #fff);
    box-shadow: 0px 2px 8px 0px rgba(29, 29, 31, 0.1);
    display: flex;
    gap: 16px;
    justify-content: space-between;
    &-icon {
      width: 28px;
      height: 28px;
    }
    &-art {
      color: var(--slate-800);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      white-space: nowrap;
    }
    &-butt {
      display: flex;
      padding: 9px 32px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 12px;
      background: var(--violet-400);
      color: var(--white, #fff);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      border: none;
      cursor: pointer;
      position: relative;
    }
  }
}
@media screen and (min-width: 300px) and (max-width: 767px) {
  .cookie {
    bottom: 70px;
    width: calc(100vw - 16px);
    right: 8px;
    &__section {
      width: 100%;
      gap: 12px;
      &-art {
        white-space: normal;
      }
    }
  }
}
</style>
