import { getLocalStorage } from '~/services/LocalStorage/localStorage'

export const setCookieExpires = () => {
  if (!getLocalStorage('USER_COOKIE_AGREMENT')) return
  const futureDate = new Date(getLocalStorage('USER_COOKIE_AGREMENT'))
  futureDate.setDate(futureDate.getDate() + 30)
  const expires = 'cookie_agreed=true; expires=' + futureDate.toUTCString()
  let cookies = document.cookie.split(';')
  cookies.forEach(function (cookie) {
    document.cookie = cookie.trim() + '; ' + expires
  })
}
